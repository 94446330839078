import { createSlice } from "@reduxjs/toolkit";

export const commentSlice = createSlice({
    name: 'comments',
    initialState: {
        comments: [],
    },
    reducers: {
        truncateComments: (state, action) => {
            // console.log('truncating comments');
            state.comments = [];
        },
        appendComments: (state, action) => {
            // console.log('appending comments', action.payload);
            // console.log('to', state.comments);
            const uniqueNewData = action.payload.filter((item) => {
                return state.comments.length === 0 || !state.comments.some((existingItem) => existingItem.id === item.id);
            });
            state.comments = [...state.comments, ...uniqueNewData];
        },
        prependComment: (state, action) => {
            console.log('new comment to prepend', action.payload);
            const uniqueNewData = action.payload.filter((item) => {
                return state.comments.length === 0 || !state.comments.some((existingItem) => existingItem.id === item.id);
            });
            state.comments = [...uniqueNewData, ...state.comments];
        }
    },
});

export const { 
    truncateComments,
    appendComments,
    prependComment,
} = commentSlice.actions;
export default commentSlice.reducer;