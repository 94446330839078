import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        preferredStore: 'amazon us'
    },
    reducers: {
        setPreferredStore: (state, action) => {
            state.preferredStore = action.payload;
        }
    },
});

export const { 
    setPreferredStore,
} = userSlice.actions;
export default userSlice.reducer;