import { createSlice } from "@reduxjs/toolkit";

export const listSlice = createSlice({
    name: 'readingLists',
    initialState: {
        myList: [],
        recent: [],
        followed: [],
        searchList: [],
        listsForBook: [],
    },
    reducers: {
        truncateMyList: (state, action) => {
            state.myList = [];
        },
        appendMyList: (state, action) => {
            const uniqueNewData = action.payload.filter((item) => {
                return state.myList.length === 0 || !state.myList.some((existingItem) => existingItem.id === item.id);
            });
            state.myList = [...state.myList, ...uniqueNewData];
        },
        truncateRecent: (state, action) => {
            state.recent = [];
        },
        appendRecent: (state, action) => {
            const uniqueNewData = action.payload.filter((item) => {
                return state.recent.length === 0 || !state.recent.some((existingItem) => existingItem.id === item.id);
            });
            state.recent = [...state.recent, ...uniqueNewData];
        },
        truncateFollowed: (state, action) => {
            state.followed = [];
        },
        appendFollowed: (state, action) => {
            const uniqueNewData = action.payload.filter((item) => {
                return state.followed.length === 0 || !state.followed.some((existingItem) => existingItem.id === item.id);
            });
            state.followed = [...state.followed, ...uniqueNewData];
        },
        truncateSearchList: (state, action) => {
            state.searchList = [];
        },
        appendSearchList: (state, action) => {
            const uniqueNewData = action.payload.filter((item) => {
                return state.searchList.length === 0 || !state.searchList.some((existingItem) => existingItem.id === item.id);
            });
            state.searchList = [...state.searchList, ...uniqueNewData];
        },
        truncateListsForBook: (state, action) => {
            state.listsForBook = [];
        },
        appendListsForBook: (state, action) => {
            const uniqueNewData = action.payload.filter((item) => {
                return state.listsForBook.length === 0 || !state.listsForBook.some((existingItem) => existingItem.id === item.id);
            });
            state.listsForBook = [...state.listsForBook, ...uniqueNewData];
        },
    },
});

export const { 
    truncateMyList,
    appendMyList,
    truncateRecent,
    appendRecent,
    truncateFollowed,
    appendFollowed,
    truncateSearchList,
    appendSearchList,
    truncateListsForBook,
    appendListsForBook
 } = listSlice.actions;
export default listSlice.reducer;