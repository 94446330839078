import { createSlice } from "@reduxjs/toolkit";

export const bookSlice = createSlice({
    name: 'books',
    initialState: {
        searchBook: [],
        listBooks: [],
        authorBooks: [],
    },
    reducers: {
        truncateSearchBook: (state, action) => {
            state.searchBook = [];
        },
        appendSearchBook: (state, action) => {
            // console.log('search book existing', state.searchBook);
            // console.log('search book new', action.payload);
            const uniqueNewData = action.payload.filter((item) => {
                return state.searchBook.length === 0 || !state.searchBook.some((existingItem) => existingItem.open_library_key === item.open_library_key);
            });
            // console.log('search book unique new', uniqueNewData);
            state.searchBook = [...state.searchBook, ...uniqueNewData];
        },
        searchBookAdded: (state, action) => {
            return {
                ...state,
                searchBook: state.searchBook.map(book =>
                    book.open_library_key === action.payload ? { ...book, in_this_series: true } : book    
                ),
            };
        },
        searchBookRemoved: (state, action) => {
            console.log('removed', action.payload);
            return {
                ...state,
                searchBook: state.searchBook.map(book =>
                    book.open_library_key === action.payload ? { ...book, in_this_series: false } : book    
                )
            }
        },
        changeReadStatus: (state, action) => {
            bookId = action.payload.bookId;
            read = action.payload.read;

            return {
                ...state,
                searchBook: state.searchBook.map(item => {
                    if (item.id === bookId) {
                        return { ...item, read: read }
                    }
                    return item;
                }),
                listBooks: state.listBooks.map(item => {
                    if (item.id === bookId) {
                        return { ...item, read: read }
                    }
                    return item;
                }),
                authorBooks: state.authorBooks.map(item => {
                    if (item.id === bookId) {
                        return { ...item, read: read }
                    }
                    return item;
                }),
            }
        },
        truncateListBooks: (state, action) => {
            state.listBooks = [];
        },
        appendListBooks: (state, action) => {
            const uniqueNewData = action.payload.filter((item) => {
                return state.listBooks.length === 0 || !state.listBooks.some((existingItem) => existingItem.open_library_key === item.open_library_key);
            });
            state.listBooks = [...state.listBooks, ...uniqueNewData];
        },
        removeFromListBooks: (state, action) => {
            // console.log('removing', action.payload);
            const updatedList = state.listBooks.filter((item) => item.id != action.payload);
            return { ...state, listBooks: updatedList };
        },
        truncateAuthorBooks: (state, action) => {
            state.authorBooks = [];
        },
        appendAuthorBooks: (state, action) => {
            console.log('authorBooks', state.authorBooks);
            console.log('new data', action.payload);
            const uniqueNewData = action.payload.filter((item) => {
                return state.authorBooks.length === 0 || !state.authorBooks.some((existingItem) => existingItem.open_library_key === item.open_library_key);
            });
            console.log('unique new data', uniqueNewData);
            state.authorBooks = [...state.authorBooks, ...uniqueNewData];
            console.log('update state', state.authorBooks);
        },
    },
});

export const { 
    truncateSearchBook,
    appendSearchBook,
    searchBookAdded,
    searchBookRemoved,
    changeReadStatus,
    truncateListBooks,
    appendListBooks,
    removeFromListBooks,
    truncateAuthorBooks,
    appendAuthorBooks,
 } = bookSlice.actions;
export default bookSlice.reducer;