const hostname = window.location.hostname;
const port = window.location.port;
const isLocalhost = hostname === 'localhost' || hostname === '127.0.0.1' || hostname === '192.168.1.104' || hostname === '192.168.85.72';
const baseURL = isLocalhost ? `http://${hostname}:${port}` : `https://${hostname}`;
// const baseURL = `http://${hostname}:${port}`;


const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
    async ({ url, method, data, params }) => {
        console.log('baseURL', baseURL);
        console.log('url', url);
        try {
            console.log(baseURL + url);
            const result = await axios({ url: baseURL + url, method, data, params })
            console.log('response',result);
            return { data: result.data }
        } catch (axiosError) {
            let err = axiosError
            console.log(err);
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            }
        }
    }

export { axiosBaseQuery, baseURL };
