import { createSlice } from "@reduxjs/toolkit";

export const eventSlice = createSlice({
    name: 'event',
    initialState: {
        listRefresh: false,
        followedListRefresh: false,
        editListRefresh: false,
        commentListRefresh: false,
        userRefresh: false,
        series: '',
        book: '',
        author: '',
    },
    reducers: {
        setListRefresh: (state, action) => {
            state.listRefresh = action.payload;
        },
        setFollowedListRefresh: (state, action) => {
            console.log('set followed list refresh');
            state.followedListRefresh = action.payload;
        },
        setEditListRefresh: (state, action) => {
            state.editListRefresh = action.payload;
        },
        setCommentListRefresh: (state, action) => {
            state.commentListRefresh = action.payload;
        },
        setUserRefresh: (state, action) => {
            state.userRefresh = action.payload;
        },
        setSeries: (state, action) => {
            console.log('setting series in the event Slice');
            state.series = action.payload;
        },
        updateSeries: (state, action) => {
            console.log('updating list details', action.payload);
            state.series = action.payload;
        },
        setBook: (state, action) => {
            console.log('saving book', action.payload);
            state.book = action.payload;
        },
        setAuthor: (state, action) => {
            state.author = action.payload;
        },
    },
});

export const { 
    setListRefresh,
    setFollowedListRefresh,
    setEditListRefresh,
    setCommentListRefresh,
    setUserRefresh,
    setSeries,
    updateSeries,
    setBook,
    setAuthor,
} = eventSlice.actions;
export default eventSlice.reducer;