import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './axiosBaseQuery';

export const apiSlice = createApi({
    tagTypes: ['List', 'ListDetails', 'Book', 'Following', 'Comment'],
    refetchOnFocus: true,
    refetchOnReconnect: true,
    baseQuery: axiosBaseQuery({
    }),
    endpoints(build) {
        return {}
    },
})

export const {} = apiSlice;