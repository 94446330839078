import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";
import { bookSlice } from "./bookSlice";
import { listSlice } from "./listSlice";
import { eventSlice } from "./eventSlice";
import { commentSlice } from "./commentSlice";
import { userSlice } from "./userSlice";

export default configureStore({
    reducer: {
        books: bookSlice.reducer,
        readingLists: listSlice.reducer,
        event: eventSlice.reducer,
        comments: commentSlice.reducer,
        user: userSlice.reducer,
        [apiSlice.reducerPath ]: apiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
})
